<template>
<div class="display-booking-position">
  <div class="d-flex w100 justify-content-between">
    <div class="top text-left">
      <h5>
        {{ this.position.getBusinessName }}
      </h5>
    </div>
    <div class="position-text text-right">
      <img class="icon" :src="require('@/assets/icons/position-pending.svg')">
      {{ position.getSimpleCreatedAt }}
    </div>
  </div>
  <div class="d-flex w100 justify-content-between position-contents">
    <div class="d-flex justify-content-between w-100 position-info flex-column flex-lg-row">
      <div>
        <div class="d-flex position-basic-info flex-row">
          <div class="text-left">
            <img
              class="business-avatar"
              :src="getAvatarUrl(position.getBusinessAvatar)"
              :alt="'avatar de' + position.getBusinessName"
            >
          </div>
          <div class="d-flex flex-column data">
            <span class="position-title">{{ position.getTitle }}
             <img
               src="@/assets/logos/logo_wo_small.png"
               alt="logo"
               class="position-jobandtalent__icon"
               id="workoutPopover"
               v-if="position.handler === HANDLER_WORKOUT"
             />
              <WorkoutDisplayTechnicianPopover
                target="workoutPopover"
                :popover="popover"
                @close="popover = !popover"
              /></span>
            <span class="position-text">
              <img class="icon" :src="require('@/assets/icons/booking.svg')">
              Fechas: {{ position.getEventDate }}
            </span>
            <span class="position-text">
              <img class="icon" :src="require('@/assets/icons/watch.svg')">
              {{ position.getOnlyTime }}
            </span>
            <span class="position-text" v-if="position.getCatering">
              <img class="icon" :src="require('@/assets/icons/coffee.svg')">
              Catering incluido
            </span>
            <span class="position-text" v-if="!position.getCatering">
              <img class="icon" :src="require('@/assets/icons/coffee.svg')">
              Catering no incluido
            </span>
            <span class="position-text" v-if="position.getAnnotation">
              <img class="icon" :src="require('@/assets/icons/text-file.svg')">
              {{ position.getAnnotation }}
            </span>
            <div class="position-price d-flex">
              <div class="d-flex flex-column">
                <span>Cada/día</span>
                <span>{{ this.pricePerDay }}</span>
              </div>
              <div class="d-flex flex-column">
                <span>Total</span>
                <span>{{ this.price}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="map-info mt-3 mt-lg-0">
        <div class="position-text text-left mb-2">
          <img class="icon" :src="require('@/assets/icons/icon-location-input.svg')">
          {{ position.getShortLocation }}
        </div>
        <Map
          :locationUrl="position.getGoogleMapsUrl"
        />
      </div>
    </div>
  </div>
  <div class="extra">
    <div v-if="position.handler === HANDLER_WORKOUT">
      <p>
        <strong>Importante</strong>: Si solicitas esta posición es que aceptas ser contratado por WORKOUT ETT.
        Si es la primera vez que prestas servicios a través de WORKOUT ETT deberás realizar la inscripción en la empresa.
        Recibirás un email con las instrucciones. Para saber más acerca de este servicio haz click <a target="_blank" :href="workoutPDF">aquí.</a>
      </p>
    </div>
    <div v-if="position.hasRoles">
      <h6>Roles</h6>
      <SkillBadge
        :name="option.name"
        :key="option.getId"
        v-for="option in position.getRoles"
      />
    </div>
    <div v-if="position.hasSkills">
      <h6>Habilidades</h6>
      <SkillBadge
        :name="option.name"
        :key="option.getId"
        v-for="option in position.getSkills"
      />
    </div>
    <div v-if="position.hasDrivingLicenses">
      <h6>Licencias de conducir</h6>
      <SkillBadge
        :name="option.name"
        :key="option.getId"
        v-for="option in position.getDrivingLicenses"
      />
    </div>
  </div>
</div>
</template>
<script>
import SkillBadge from '@/components/SkillBadge/SkillBadge'
import Map from '@/components/Map/Map'
import { HANDLER_WORKOUT } from '@/domain/models/BookingPosition'
import WorkoutDisplayTechnicianPopover from '@/components/Workout/Popover/DisplayBookingTechnician'

export default {
  name: 'DisplayBookingPosition',
  components: {
    WorkoutDisplayTechnicianPopover,
    SkillBadge,
    Map
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    workoutPDF () {
      return process.env.VUE_APP_IMAGE_API_URL + '/workout/workout_technician.pdf'
    },
    pricePerDay () {
      return this.position.getPricePerDay + '€'
    },
    price () {
      return this.position.getPrice + '€'
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL,
      popover: false,
      HANDLER_WORKOUT
    }
  },
  created () {
    this.setPositionAsSaw()
  },
  watch: {
    position: function () {
      this.setPositionAsSaw()
    }
  },
  methods: {
    getAvatarUrl (avatar) {
      if (!avatar) {
        return '/img/default-user-avatar.svg'
      }

      return this.baseUrl + avatar.path
    },
    setPositionAsSaw () {
      const data = {
        bookingPositionId: this.$route.params.id,
        technicianSawIt: true
      }

      this.$store.dispatch('updateContactedTechnicianAsTechnician', data)
        .then(() => {
          this
            .$store
            .dispatch('getContactedTechnicianAsTechnician')
        })
    }
  }
}
</script>
<style lang="less" scoped>
  @import '../../style/colors.less';
  @import '../../style/text.less';
  @import "../../style/responsive";

  .display-booking-position {
    background-color: @color_dark_purple;
    padding: 2rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    .top {
      margin-bottom: 1.5rem;
      h5 {
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
      }
    }
    .position-contents {
      .business-avatar {
        width: 80px;
      }
      .data {
        margin-left: 1.5rem;
        text-align: left;

        > span {
          margin-bottom: 1rem;
        }
        .position-title {
          color: @color_radiant_pink;
          font-size: 1rem;
          font-weight: 500;
        }
        .position-price {
          font-family: @font_secondary;
          font-weight: 500 !important;
          font-size: 0.65rem;
          color: #D5D5DC;
          > div {
            background-color: @color_dark_blue_light;
            border-radius: 5px;
            margin-right: 0.82rem;
            padding: 0.7rem 0.9rem;
            text-align: center;
            min-width: 84px;
            @media (max-width: @break_xs) {
              min-width: auto;
            }
          }
        }
      }

      @media (max-width: @break_xs) {
        flex-direction: column;
      }
    }
    .extra {
      margin-top: 4rem;
      text-align: left;
      > div {
        border-bottom: 1px solid #ffffff51;
        padding-bottom: 0.7rem;
        margin-bottom: 1rem;
        &:last-of-type {
          padding: 0;
          margin: 0;
          border-bottom-width: 0;
        }
      }
    }
    .position-text {
      font-size: 0.75rem;
    }
    img.icon {
      width: 14px;
    }
  }
</style>
